import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from '../../../layouts/mdx';
export const _frontmatter = {
  "product": "verify",
  "category": "Integrations",
  "sort": 0,
  "title": "React",
  "subtitle": "Accept Danish MitID, Swedish BankID, Norwegian BankID and more eID logins in your React app with @criipto/verify-react."
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`This guide shows you how to use Criipto Verify in a React single page application. Read the `}<a parentName="p" {...{
        "href": "https://github.com/criipto/criipto-verify-react"
      }}>{`full documentation`}</a>{` or `}<a parentName="p" {...{
        "href": "https://github.com/criipto/criipto-verify-react/tree/master/example"
      }}>{`download a sample application from GitHub`}</a>{`.`}</p>
    <h2>{`App switch support`}</h2>
    <p><inlineCode parentName="p">{`@criipto/verify-react`}</inlineCode>{` supports app switching for Swedish BankID and Danish MitID by a best-effort mobile-os detection and setting the relevant Criipto Verify login hints.`}</p>
    <h2>{`Installation`}</h2>
    <p>{`Using `}<a parentName="p" {...{
        "href": "https://npmjs.org/"
      }}>{`npm`}</a></p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`npm install @criipto/verify-react
`}</code></pre>
    <h2>{`Setup SDK`}</h2>
    <p>{`Setup the Criipto Verify SDK by wrapping your application in `}<inlineCode parentName="p">{`CriiptoVerifyProvider`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// src/index.js
import React from 'react';
import ReactDOM from 'react-dom';
import { CriiptoVerifyProvider } from '@criipto/verify-react';

import App from './App';

ReactDOM.render(
  <CriiptoVerifyProvider
    domain="{{YOUR_CRIIPTO_DOMAIN}}"
    clientID="{{YOUR_CLIENT_ID}}"
    redirectUri={window.location.href}
  >
    <App />
  </CriiptoVerifyProvider>,
  document.getElementById('root')
);
`}</code></pre>
    <h2>{`Trigger login with redirect`}</h2>
    <p>{`For a simple redirect based login you can use the `}<inlineCode parentName="p">{`loginWithRedirect`}</inlineCode>{` option via `}<inlineCode parentName="p">{`useCriiptoVerify`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// src/App.js
import React from 'react';
import { useCriiptoVerify } from '@criipto/verify-react';

export default function App() {
  const {result, loginWithRedirect} = useCriiptoVerify();

  if (result?.id_token) {
    return (
      <pre>
        {JSON.stringify(result.id_token, null, 2)}
      </pre>
    )
  }

  return (
    <React.Fragment>
      {result?.error ? (
        <p>
          An error occurred: {result.error} ({result.error_description}). Please try again:
        </p>
      ) : null}
      <button onClick={() => loginWithRedirect()}>Login</button>
    </React.Fragment>
  );
}
`}</code></pre>
    <h2>{`Render login options`}</h2>
    <p>{`If you support multiple eIDs and wish to render login options in your app you can do so with the `}<inlineCode parentName="p">{`AuthMethodSelector`}</inlineCode>{` component:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// src/App.js
import React from 'react';
import { useCriiptoVerify, AuthMethodSelector } from '@criipto/verify-react';

export default function App() {
  const {result} = useCriiptoVerify();

  if (result?.id_token) {
    return (
      <pre>
        {JSON.stringify(result.id_token, null, 2)}
      </pre>
    )
  }

  return (
    <React.Fragment>
      {result?.error ? (
        <p>
          An error occurred: {result.error} ({result.error_description}). Please try again:
        </p>
      ) : null}
      <AuthMethodSelector />
    </React.Fragment>
  );
}
`}</code></pre>
    <h2>{`Sessions`}</h2>
    <p>{`If you want to use `}<inlineCode parentName="p">{`@criipto/verify-react`}</inlineCode>{` for session management (rather than one-off authentication) you can configure a `}<inlineCode parentName="p">{`sessionStore`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// src/index.js
import React from 'react';
import ReactDOM from 'react-dom';
import { CriiptoVerifyProvider } from '@criipto/verify-react';

import App from './App';

ReactDOM.render(
  <CriiptoVerifyProvider
    domain="{{YOUR_CRIIPTO_DOMAIN}}"
    clientID="{{YOUR_CLIENT_ID}}"
    redirectUri={window.location.href}
    sessionStore={window.sessionStorage} // or window.localStorage
  >
    <App />
  </CriiptoVerifyProvider>,
  document.getElementById('root')
);
`}</code></pre>
    <p>{`When a sessionStore is configured the library will store the id_token your chosen storage (sessionStorage or localStorage) and invalidate the token once it expires.`}</p>
    <p>{`The library will also attempt to retrieve a user token on page load via SSO (if your criipto domain has SSO enabled).`}</p>
    <p>{`You may wish to increase the "Token lifetime" setting of your Criipto Application.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// src/App.js
import React from 'react';
import { useCriiptoVerify, AuthMethodSelector } from '@criipto/verify-react';

export default function App() {
  const {claims, error, isLoading} = useCriiptoVerify();

  if (isLoading) {
    return <div>Loading</div>
  }

  if (claims) {
    return (
      <pre>
        {JSON.stringify(claims, null, 2)}
      </pre>
    )
  }

  return (
    <React.Fragment>
      {error ? (
        <p>
          An error occurred: {error.error} ({error.error_description}). Please try again:
        </p>
      ) : null}
      <AuthMethodSelector />
    </React.Fragment>
  );
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      